import { GreetingComponent } from '../greeting.component'
import { Routes } from '@angular/router'
import { OldRoutes } from './old.routes'
import { OldRoutesComponent } from './old.routes.component'

const adapted = OldRoutes.map(path => ({
  path: path.substr(1),
  component: OldRoutesComponent
}))

adapted.push({
  path: 'test',
  component: GreetingComponent
})

const routes: Routes = adapted;

export {
  routes
}
