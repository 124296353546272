import * as Raven from 'raven-js'
import { NgModule, ErrorHandler } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { APP_BASE_HREF } from '@angular/common'

import { AppComponent } from './app.component'
import { AppRoutingModule } from '../router/router.module'
import { OldRoutesComponent } from '../router/old.routes.component'
import { GreetingComponent } from '../greeting.component'

Raven
  .config('https://513d0592ab0b4a22ba20e0da1f4aec78@sentry.io/1056021')
  .install()

export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    Raven.captureException(err)
  }
}

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  declarations: [
    AppComponent,
    OldRoutesComponent,
    GreetingComponent
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    },
    {
      provide: ErrorHandler,
      useClass: RavenErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
