const oldRoutes = [
  '/',
  '/actions',
  '/actions/:id',
  '/actions/new',
  '/activities',
  '/activities/:id',
  '/activities/new',
  '/businesses',
  '/businesses/:id',
  '/businesses/new',
  '/certifications',
  '/challenges',
  '/challenges/:id',
  '/challenges/new',
  '/challengeCalendar',
  '/charts',
  '/charts/:id',
  '/charts/new',
  '/categories',
  '/categories/:id',
  '/categories/new',
  '/collections',
  '/communityConfig',
  '/groups',
  '/groups/:id',
  '/groups/new',
  '/goals',
  '/goals/:id',
  '/goals/new',
  '/communities',
  '/communities/:id',
  '/communities/:id/:tab',
  '/communities/new',
  '/communityActivities',
  '/communityActivities/:id',
  '/communityActivities/new',
  '/communityUsers',
  '/communityUsers/create',
  '/communityUsers/:id',
  '/communityUsers/:userId/activities/:id',
  '/communityUsers/:userId/activities/new',
  '/content',
  '/customActivities',
  '/customActivities/:id',
  '/customActivities/new',
  '/dailyPolls',
  '/dailyPolls/:id',
  '/dailyPolls/new',
  '/events',
  '/events/:id',
  '/events/:id/instance/:instance',
  '/events/new',
  '/eventTemplates',
  '/eventTemplates/:id',
  '/eventTemplates/new',
  '/leaderboards',
  '/moderation',
  '/notifications',
  '/signin',
  '/studioApps',
  '/gds',
  '/segments',
  '/segments/:id',
  '/segments/new',
  '/segmentTypes',
  '/segmentTypes/:id',
  '/segmentTypes/new',
  '/surveys',
  '/surveys/:id',
  '/surveys/new',
  '/surveyQuestions',
  '/surveyQuestions/:id',
  '/surveyQuestions/new',
  '/volunteerings',
  '/volunteerings/:id',
  '/volunteerings/new',
  '/services',
  '/services/:id',
  '/services/new',
  '/pushes',
  '/pushes/new',
  '/pushes/:id',
  '/sprints',
  '/whitelabel',
  '/teams',
  '/teams/:id',
  '/teams/new',
  '/templates',
  '/users/:userId/activities/:id',
  '/users/:userId/activities/new',
  '/uncodedBusinesses',
  '/users',
  '/users/create',
  '/users/:id',
  '/organizations',
  '/schools',
  '/test',
  '/utilities',
  '/legacy/events',
  '/legacy/events/:id',
  '/legacy/events/new',
  '#'
]

export {
  oldRoutes as OldRoutes
}
