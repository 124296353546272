import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { enableProdMode } from '@angular/core'
import { isProduction } from './helpers'

import { AppModule } from './app/app.module'

if (isProduction()) {
  enableProdMode()
}

platformBrowserDynamic().bootstrapModule(AppModule)
